import fetch from "common/js/fetch";
import { getUserId } from "../js/util";
import { isObject } from "lodash";

// 新增物流对象
export function createLogistics(config = {}) {
  return fetch("/core/v1/logistics/create", config);
}

// 修改物流对象
export function editLogistics(config = {}) {
  return fetch("/core/v1/logistics/modify", config);
}

// 病例列表
export function queryCasesList(config = {}) {
  return fetch("/core/v1/case/list", config);
}

// 分页条件查询病例
export function queryCasesPage(config = {}) {
  return fetch("/core/v1/case/page", {
    ...config
  });
}

export function queryStageProgressList(config) {
  return fetch("/core/v1/cure_note/progress", {
    ...config
  });
}

// 病例状态数量统计
export function casesStatistical(config) {
  return fetch("/core/v1/case/status_count_statistic", config);
}

// 列表条件查询诊所
export function clinicList() {
  return fetch("/core/v1/clinic_doctor/list", {
    status: "1",
    doctorId: getUserId()
  });
}

// 列表条件查询产品
export function productList(config = {}) {
  return fetch("/core/v1/product/list", { ...config, status: "1" });
}

// 病例转irdt
export function curePlanOpenIrdt(config) {
  return fetch("/core/v1/cure_plan/open_rdt", config);
}

//
export function curePlanToCompleteNs(config) {
  return fetch("/core/v1/cure_plan/to_complete_ns", config);
}

// 可选产品列表
export function optionalListByProduct(config) {
  return fetch("/core/v1/product/optional_list", { ...config });
}

// 列表条件查询病例-病例详情页
export function caseListCaseDetail(config) {
  return fetch("/core/v1/case/list/case_detail", config);
}

// 列表条件诊所医生关联
export function clinicDoctorList(config) {
  return fetch("/core/v1/clinic_doctor/list", config);
}

// 新增病例对象
export function createCases(config) {
  return fetch("/core/v1/case/create", config);
}

// 修改病例对象
export function editCases(config) {
  return fetch("/core/v1/case/modify", config);
}

// 查询病例对象
export function casesDetail(query) {
  if (query.id) {
    return fetch(`/core/v1/case/detail/${query.id}`, { token: query.token });
  } else {
    return fetch(`/core/v1/case/detail/${query}`);
  }
}

// 新增治疗方案对象
export function createCurePlan(config) {
  return fetch(`/core/v1/cure_plan/create`, config);
}

// 开启irdt获取治疗表信息
export function columnDataTempRecordDetail(config) {
  return fetch(`/core/v1/column_data_temp_record/detail`, config);
}

export function columnDataTempRecordSave(config) {
  return fetch(`/core/v1/column_data_temp_record/save`, config);
}

// 修改治疗方案对象
export function editCurePlan(config) {
  return fetch(`/core/v1/cure_plan/modify`, config);
}

// 查询治疗方案对象
export function detailCurePlan(query) {
  if (query.id) {
    return fetch(`/core/v1/cure_plan/detail/${query.id}`, {
      token: query.token
    });
  } else {
    return fetch(`/core/v1/cure_plan/detail/${query}`);
  }
}

// 查询治疗方案待完善信息
export function toCompleteCurePlan(config) {
  return fetch(`/core/v1/cure_plan/to_complete`, config);
}

// 根据用户查询账户对象
export function accountByUser(config) {
  return fetch(`/core/v1/account/detailByUser`, config);
}

// 医生支付设计费用
export function payDesignFee(config) {
  return fetch(`/core/v1/cure_plan/pay_design_fee`, config);
}

// 医生支付生产费用
export function payProductionFee(config) {
  return fetch(`/core/v1/cure_plan/pay_production_fee`, config);
}

// 医生提交
export function doctorSubmitCurePlan(id) {
  return fetch(`/core/v1/cure_plan/doctor_submit`, { id });
}

// 列表条件查询物流
export function logisticsList(config) {
  return fetch(`/core/v1/logistics/list`, config);
}

//
export function queryAesNoteList(config) {
  return fetch(`/core/v1/yueya/cure_plan/aes_note_list/${config}`);
}

// 查询物流对象
export function logisticsDetail(id) {
  return fetch(`/core/v1/logistics/detail/${id}`);
}

// 医生确认治疗方案
export function confirmCurePlan(config) {
  return fetch(`/core/v1/cure_plan/doctor_confirm_plan`, config);
}

export function yueyaConfirmCurePlan(config) {
  return fetch(`/core/v1/yueya/cure_plan/doctor_confirm_plan`, config);
}

// 继续发货
export function deliverAgain(config) {
  return fetch(`/core/v1/cure_plan/deliver_again`, config);
}

// 重新调整
export function curePlanReadjust(config) {
  return fetch(`/core/v1/cure_plan/adjust`, config);
}

// 确认收货
export function deliverReceive(config) {
  return fetch(`/core/v1/logistics/receive`, config);
}

export function logisticsCaseReceive(config) {
  return fetch(`/core/v1/logistics_case/receive`, config);
}

// 修改备注
export function casesModifyRemark(config) {
  return fetch(`/core/v1/case/modify_remark`, config);
}

// 列表条件查询病例进度
export function caseProgressList(config) {
  return fetch(`/core/v1/case_progress/list`, config);
}

// 列表条件查询方案说明
export function caseNoteList(config) {
  return fetch(`/core/v1/cure_note/list`, config);
}

// 列表条件查询操作日志
export function operatorLogList(config) {
  return fetch(`/core/v1/operator_log/list`, config);
}

// 新增佩戴记录对象
export function addWearRecord(config) {
  return fetch(`/core/v1/wear_record/create`, config);
}

// 查询佩戴记录对象
export function wearRecordDetail(id) {
  return fetch(`/core/v1/wear_record/detail/${id}`);
}

// 查询佩戴记录列表
export function wearRecordList(config) {
  return fetch(`/core/v1/wear_record/list`, config);
}

// 修改下一套佩戴提醒时间
export function modifyNextDatetime(config) {
  return fetch(`/core/v1/case/modify_next_wear_remain_datetime`, config);
}

// 做保持
export function curePlanRemain(config) {
  return fetch(`/core/v1/cure_plan/remain`, config);
}

// 完成病例
export function cureDone(config) {
  return fetch(`/core/v1/case/done`, config);
}

// 归档病例
export function cureArchive(config) {
  return fetch(`/core/v1/case/archive`, config);
}

// 重启病例
export function cureReopen(config) {
  return fetch(`/core/v1/case/reopen`, config);
}

// 新增病例转让记录对象
export function addCaseTransferRecord(config) {
  return fetch(`/core/v1/case_transfer_record/create`, config);
}

// 医生提交修改建议
export function operatorLogDoctor(config) {
  return fetch(`/core/v1/operator_log/doctor_suggest`, config);
}

// 普通医嘱
export function addCaseMessage(config) {
  return fetch(`/core/v1/case_message/case_chat`, config);
}

// 邀请上门
export function addCaseInvite(config) {
  return fetch(`/core/v1/case_message/invite`, config);
}

// 列表条件查询病例聊天记录
export function caseChatList(config) {
  return fetch(`/core/v1/case_chat/list`, config);
}

// 分页条件查询病例推送消息
export function caseMessagePage(config) {
  return fetch(`/core/v1/case_message/page`, {
    pageNum: 1,
    pageSize: 3,
    ...config
  });
}

// 查询病例推送消息对象
export function caseChatHandle(id) {
  return fetch(`/core/v1/case_message/handle`, { id });
}

// 列表条件查询方案问题列表
export function cureProblemList(config = {}) {
  return fetch(`/core/v1/cure_problem/list`, {
    ...config,
    sort: "groupNo",
    backNode: "to_submit",
    newFlag: "1"
  });
}

export function caseDoneProblem(config = {}) {
  return fetch(`/core/v1/cure_problem/list`, {
    ...config,
    sort: "groupNo",
    backNode: "to_done",
    newFlag: "1"
  });
}

// 重新扫描
export function curePlanRescan(config = {}) {
  return fetch(`/core/v1/cure_plan/rescan`, config);
}

// 医生审核文字方案
export function confirmTextPlan(config) {
  return fetch(`/core/v1/cure_plan/confirm_text`, config);
}

// 查询方案修改建议对象
export function cureSuggestDetail(id) {
  return fetch(`/core/v1/cure_suggest/detail/${id}`);
}

// 列表条件查询方案修改建议
export function cureSuggestList(query) {
  if (isObject(query) && query.caseId) {
    return fetch(`/core/v1/cure_note/list`, {
      caseId: query.caseId,
      token: query.token
    });
  }
  return fetch(`/core/v1/cure_note/list`, { caseId: query });
}

// 查询厂商邮寄地址对象
export function addressDetailOss() {
  return fetch(`/core/v1/address/detail_oss`);
}

// 查询实时物流信息
export function logisticsCurrent(config) {
  return fetch(`/core/v1/logistics/current`, config);
}

// 查询问题报表
export function problemStatement() {
  return fetch(`/core/v1/prefer_question/question_report`, {
    categoryList: ["clinic", "product"]
  });
}

// 列表条件查询临床偏好回答
export function preferAnswerList(config = {}) {
  return fetch(`/core/v1/prefer_answer/list`, config);
}

// 批量新增临床偏好回答对象
export function preferAnswerCreate(config = {}) {
  return fetch(`/core/v1/prefer_answer/batch_create`, config);
}

// 删除病例对象
export function removeCase(id) {
  return fetch(`/core/v1/case/remove/${id}`);
}

export function removeTempCase(id) {
  return fetch(`/core/v1/column_data_temp_record/remove/${id}`);
}

// 删除病例推送消息对象
export function removeCaseMessage(id) {
  return fetch(`/core/v1/case_message/remove/${id}`);
}

// 方案说明
export function coreCureNoteList(config) {
  return fetch(`/core/v1/cure_note/list`, config);
}

// 诊所详情
export function coreClinicDetail(id) {
  return fetch(`/core/v1/clinic/detail/${id}`);
}

// 病例资料 列表
export function caseDiagnosisList(config) {
  return fetch(`/core/v1/diagnosis/list`, config);
}

// 病例资料详情
export function caseDiagnosisDetail(query) {
  if (isObject(query) && query.id) {
    return fetch(`/core/v1/diagnosis/detail/${query.id}`, {
      token: query.token
    });
  } else {
    return fetch(`/core/v1/diagnosis/detail/${query}`);
  }
}

// 病例资料 列表
export function caseCureNoteList(config) {
  return fetch(`/core/v1/cure_note/list`, config);
}

// 3d病例详情
export function caseCureNoteDetail(query) {
  if (isObject(query) && query.id) {
    return fetch(`/core/v1/cure_note/detail/${query.id}`, {
      token: query.token
    });
  } else {
    return fetch(`/core/v1/cure_note/detail/${query}`);
  }
}

// 查询当前环境
export function PublicQueryEnvironment(config) {
  return fetch("/auth/v1/config/list", config);
}

// 查询邮寄地址
export function PublicAddressDetailCase(config) {
  return fetch(`/core/v1/address/detail_case/${config}`);
}

// 追加生产产品列表
export function PublicProductTypeListByDoctor(config) {
  return fetch(`/core/v1/product_type/list_by_doctor`, {
    platformType: "0",
    ...config
  });
}

// 提交追加
export function PublicCaseAdd(config) {
  return fetch(`/core/v1/case/add`, config);
}

// 追加历史列表
export function PublicCaseListAdditional(config) {
  return fetch(`/core/v1/case/list_additional`, config);
}

// 追加进度列表
export function PublicCaseProgressListAdditional(config) {
  return fetch(`/core/v1/case_progress/list_additional`, config);
}

// 历史记录
export function PublicAttachmentLogList(config) {
  return fetch(`/core/v1/attachment_log/list`, config);
}

// 颜色列表
export function PublicPlasticSheetList(config) {
  return fetch(`/core/v1/plastic_sheet/list`, { ...config, sort: "-orderNo" });
}

// irdt 修改意见
export function PublicCurePlanApprovePlan(config) {
  return fetch(`/core/v1/cure_plan/approve_plan`, config);
}

// irdt 批准方案
export function PublicCurePlanAppRoveConfirmPlan(config) {
  return fetch(`/core/v1/cure_plan/confirm_plan`, config);
}

//查询诊所地址详情
export function PublicSendAddressDetail(config) {
  return fetch(`/core/v1/send_address/detail`, config);
}

//查询诊所收货地址详情
export function queryClinicAddressDetail(id) {
  return fetch(`/core/v1/address/detail_clinic/${id}`);
}

//查询诊所地址
export function PublicSendAddressDetailClinic(id) {
  return fetch(`/core/v1/send_address/detail_clinic/${id}`);
}

//查询个人地址
export function PublicSendAddressList(config) {
  return fetch(`/core/v1/send_address/list`, config);
}

// 修改地址
export function PublicSendAddressModify(config) {
  return fetch(`/core/v1/send_address/modify`, config);
}

// 新增地址
export function PublicSendAddressCreate(config) {
  return fetch(`/core/v1/send_address/create`, config);
}

// 查询寄件地址对象
export function PublicSendAddressDetailId(id) {
  return fetch(`/core/v1/send_address/detail/${id}`);
}

// 新增修改病例寄件地址关联
export function PublicCaseSendAddressCreateModify(config) {
  return fetch(`/core/v1/case_send_address/create_modify`, config);
}

// 删除病例寄件地址关联
export function PublicCaseSendAddressRemove(config) {
  return fetch(`/core/v1/case_send_address/remove`, config);
}

// 查询是否关联
export function PublicCaseSendAddressDetail(config) {
  return fetch(`/core/v1/case_send_address/detail`, config);
}

// 下单校验
export function PublicPickUpOrderCheck(config) {
  return fetch(`/core/v1/pickup/order_check`, config);
}

// 上门取件邮寄
export function PublicPickUpOrder(config) {
  return fetch(`/core/v1/pickup/order`, config);
}

// 订单信息 地址详情
export function PublicPickupRegionalCheck(config) {
  return fetch(`/core/v1/pickup/regional_check`, config);
}

// 创建订单
export function PublicLogisticsCreate(config) {
  return fetch(`/core/v1/logistics/create`, config);
}

// 订单模型列表
export function PublicLogisticsPage(config) {
  return fetch(`/core/v1/logistics/page`, config);
}

// 上门取件订单取消
export function PublicPickupCancel(config) {
  return fetch(`/core/v1/pickup/cancel`, config);
}

// 查询物流对象
export function PublicLogisticsDetailStatus(id) {
  return fetch(`/core/v1/logistics/detail/${id}`);
}

// 分页条件物流病例关联
export function PublicLogisticsCasePage(config) {
  return fetch(`/core/v1/logistics_case/page`, config);
}

export function PublicUserCasePage(config) {
  return fetch(`/auth/v1/user/case_page`, config);
}

export function case_page(config) {
  return fetch(`/auth/v1/user/case_page/doctor`, config);
}

// 病例列表 关注
export function follow_case(config) {
  return fetch(`/auth/v1/user/follow`, config);
}

// 查询物流病例关联对象
export function PublicLogisticsCaseDetail(id) {
  return fetch(`/core/v1/logistics_case/detail/${id}`);
}

// 查询邮寄详情
export function PublicAddressDetailUser(config) {
  return fetch(`/core/v1/address/detail_user`, config);
}

// 完成病例 暂存
export function curePlanCompletion_save(config) {
  return fetch(`/core/v1/column_data_temp_record/save`, config);
}

// 完成病例 提交
export function curePlanCompletion(config) {
  return fetch(`/core/v1/cure_plan/completion`, config);
}

export function toCompleteCompletion(config) {
  return fetch(`/core/v1/cure_plan/to_complete_completion`, config);
}

export function caseDoneDetail(config) {
  return fetch(`/core/v1/column_data_temp_record/detail`, config);
}

export function clinicAddress(config) {
  return fetch(`/core/v1/address/list`, config);
}

export function bracesReissuePage(config) {
  return fetch(`/core/v1/braces_reissue/page`, config);
}

export function bracesReissueDetail(config) {
  return fetch(`/core/v1/braces_reissue/detail/${config}`);
}

export function createBracesReissue(config) {
  return fetch(`/core/v1/braces_reissue/create_by_doctor`, config);
}

export function createUnSubmit(config) {
  return fetch(`/core/v1/braces_reissue/create_un_submit`, config);
}

export function submitAndTipsFlag(config) {
  return fetch(`/core/v1/braces_reissue/submit_and_tips_flag/${config}`);
}

export function supendedService(config) {
  return fetch(`/core/v1/case/suspended_service/${config}`);
}

export function submitSilicone(config) {
  return fetch(`/core/v1/case/submit_silicone`, config);
}

export function submitOrthodontic(config) {
  return fetch(`/core/v1/case/submit_orthodontic`, config);
}

export function submitInvisible(config) {
  return fetch(`/core/v1/case/submit_invisible`, config);
}

export function removeSendAddress(config) {
  return fetch(`/core/v1/send_address/remove_batch`, config);
}

export function transferVeneer(config) {
  return fetch(`/core/v1/case/transfer_veneer`, config);
}

export function threeDimensionalModification(config) {
  return fetch(`/core/v1/three_dimensional_design_plan/modification`, config);
}

export function threeDimensionalDetail(config) {
  if ("token" in config) {
    return fetch(`/core/v1/three_dimensional_design_plan/detail/${config.id}`, {
      token: config.token
    });
  } else {
    return fetch(`/core/v1/three_dimensional_design_plan/detail/${config.id}`);
  }
}

export function getUserDetailByDetail(config) {
  return fetch(`/auth/v1/user/permission_none/my`, config);
}

export function threeDesignPlanModDetail(config) {
  return fetch(
    `/core/v1/three_dimensional_design_plan/modification/detail`,
    config
  );
}

export function curePlanDesignList(config) {
  return fetch(`/core/v1/cure_plan/design/list`, config);
}

export function threeDThemeDetail(config) {
  return fetch(`/core/v1/three_dimensional_theme_setting/detail`, config);
}

export function modifyThreeDThemeSettingBackgroundColor(config) {
  return fetch(
    `/core/v1/three_dimensional_theme_setting/modify_background_color`,
    config
  );
}

export function modifyThreeDThemeSettingBackgroundPic(config) {
  return fetch(
    `/core/v1/three_dimensional_theme_setting/modify_background_pic`,
    config
  );
}

export function modifyThreeDThemeSettingType(config) {
  return fetch(
    `/core/v1/three_dimensional_theme_setting/modify_theme_type`,
    config
  );
}

export function curePlanPhaseAdjustment(config) {
  return fetch(`/core/v1/cure_plan/phase_adjustment`, config);
}

// 方案咨询 暂存
export function schemeSave(config) {
  return fetch(`/core/v1/work_order/scheme_save`, config);
}

//  工单 方案咨询  详情
export function schemeDetail(id) {
  return fetch(`/core/v1/work_order/detail/${id}`);
}

//  工单 方案咨询  page
export function schemePage(config) {
  return fetch(`/core/v1/work_order/page`, config);
}

//  工单 方案咨询  数量
export function schemeCount(config) {
  return fetch(`/core/v1/work_order/count`, config);
}

//  工单 方案咨询  查询完善信息
export function schemeComplete(config) {
  return fetch(`/core/v1/work_order/to_complete`, config);
}

//  工单 方案咨询  提交
export function schemeSubmit(config) {
  return fetch(`/core/v1/work_order/scheme_submit`, config);
}

//  工单 方案咨询  回复
export function schemeReply(config) {
  return fetch(`/core/v1/work_order/reply`, config);
}

//  工单 方案咨询沟通记录
export function schemeMessagePage(config) {
  return fetch(`/core/v1/work_order_message/page`, config);
}

//  工单 方案咨询 关闭
export function schemeClose(config) {
  return fetch(`/core/v1/work_order/close`, config);
}

//  工单 方案咨询 删除
export function schemeRemove(config) {
  return fetch(`/core/v1/work_order/delete`, config);
}

//  工单 方案咨询 评价
export function schemeEvaluate(config) {
  return fetch(`/core/v1/work_order/evaluate`, config);
}

//  工单 方案咨询 转病例
export function schemeTurnCase(config) {
  return fetch(`/core/v1/work_order/scheme_turn_case`, config);
}

//  首页 草稿统计
export function case_statistic(config) {
  return fetch(`/core/v1/case/statistic`, config);
}

//  首页 统计
export function case_page_draft(config) {
  return fetch(`/core/v1/case/page_draft`, config);
}

// 病例详情 归档
export function case_archive(config) {
  return fetch(`/core/v1/case/archive`, config);
}

// 服务截止日期
export function case_service_list(config) {
  return fetch(`/core/v1/case/service_detail`, config);
}

// 可更改产品列表
export function product_replace_list(config) {
  return fetch(`/core/v1/product/replace_list`, config);
}

// 更改产品
export function replace_product(config) {
  return fetch(`/core/v1/case/replace_product`, config);
}

// 详情页-按钮
export function detail_button(config) {
  return fetch(`/core/v1/case/detail/button`, config);
}

// 产品类型-关联产品
export function product_relevant(config) {
  return fetch(`/core/v1/product_type/case_relevant`, config);
}

// 诊所-关联产品
export function clinic_relevant(config) {
  return fetch(`/core/v1/clinic/case_relevant`, config);
}

// 服务截止日期查询
export function service_deadline_reminder(config) {
  return fetch(`/core/v1/case/service_deadline_reminder`, config);
}

// 服务截止日期关闭
export function close_reminder(config) {
  return fetch(`/core/v1/case/close_reminder`, config);
}

export function product_detail(id) {
  return fetch(`/core/v1/product/detail/${id}`);
}
